var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var detectPointer = {
  update: function update() {
    if (typeof window !== "undefined" && typeof window.matchMedia === "function") {
      detectPointer.fine = window.matchMedia("(pointer: fine)").matches;
      detectPointer.coarse = window.matchMedia("(pointer: coarse)").matches;
      detectPointer.none = window.matchMedia("(pointer: none)").matches;
      detectPointer.anyFine = window.matchMedia("(any-pointer: fine)").matches;
      detectPointer.anyCoarse = window.matchMedia("(any-pointer: coarse)").matches;
      detectPointer.anyNone = window.matchMedia("(any-pointer: none)").matches;
    }
  }
};
detectPointer.update();
exports.default = detectPointer;
export default exports;
export const __esModule = exports.__esModule;